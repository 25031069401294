import { useTheme } from "@emotion/react";
import { Carousel } from "../component_library/carousel";
import { ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { Localization } from "../component_library/localization";
import { toHTML } from "../component_library/paragraph";
import { NewsCard } from "./news_card";
import { Box } from "@mui/material";

export function NewsSection({backgroundColor}) {
    const backgroundImg = "images/news_section_background.png";
    const theme = useTheme();

    return (
        <Box id="news">
        <ContentPanel 
        backgroundImageSrc={backgroundImg} 
        color={backgroundColor ? backgroundColor : theme.palette.background.default} 
        >
            <ContentPanelTitle>{Localization.get("latest_news")}</ContentPanelTitle>
                <NewsCard
                    img="images/db_demo_banner.jpg"
                    title={toHTML(Localization.get("disaster_blaster_demo_coming_soon_title"))}
                    body={toHTML(Localization.get("disaster_blaster_demo_coming_soon_body"))}
                />
                <></>
                <></>
        </ContentPanel>
        </Box>
    );
}