import { useState } from "react";
import { CardContainer } from "../component_library/containers"
import { Image } from "../component_library/image"
import { Paragraph, ParagraphBody, ParagraphTitle } from "../component_library/paragraph";
import { Col, ContentPanel, Row } from "../component_library/layout";
import { IconButton, Modal, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

export const NewsCard = ({ img, title, body }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <Box onClick={handleOpen} sx={{ cursor: 'pointer' }}>
        <CardContainer>
          <Col weights={[9, 3]} spacing={1}>
            <Image src={img} />
            <Paragraph>
              <ParagraphTitle>{title}</ParagraphTitle>
            </Paragraph>
          </Col>
        </CardContainer>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
          width={"100%"}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              color: "white",
              position: "absolute",
              top: "0",
              right: "0",
            }}>
            <CloseIcon />
          </IconButton>
          <ContentPanel color={theme.palette.background.paper}>
            <Row>
              <Image src={img} />
            </Row>
            <Row>
              <Paragraph>
                <ParagraphTitle>{title}</ParagraphTitle>
                <ParagraphBody>{body}</ParagraphBody>
              </Paragraph>
            </Row>
          </ContentPanel>
        </Box>
      </Modal>
    </>
  );
};