import * as React from "react";
import {
  Col,
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Box, Button, Container, Grow, Icon, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
} from "../component_library/paragraph";
import { Image } from "../component_library/image";
import { ContactInfoSection } from "../components/contact_info_section";
import { toHTML } from "../component_library/paragraph";
import { useState } from "react";
import { Localization } from "../component_library/localization";
import CloseIcon from "@mui/icons-material/Close";
import { JarsickWebsiteButton, JarsickWebsiteIconButton } from "../components/jarsick_website_button";
import { useNavigate } from "react-router-dom";
import { NewsSection } from "../components/news_section";

function BannerSection() {
  const theme = useTheme();
  const bannerGifSrc = "gifs/db_banner.gif";

  const color = "#21102F";
  const backgroundImageSrc = "images/homepage_db_background.jpg";
  const bannerSmallScreen = "images/homepage_small_db_banner.jpg";
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const containerStyle = {
    backgroundColor: color,
    backgroundImage: `url(${backgroundImageSrc})`,
    backgroundSize: "cover",
    paddingTop: "7rem",
    display: "flex",
    justifyContent: "center",
  };

  return (

    <div>
      <>
        {isSmallScreen ?
          <ContentPanel backgroundImageSrc={backgroundImageSrc}>
            <Box onClick={handleOpen} sx={{ paddingTop: "3rem" }}>
              <div title="Disaster Blaster Banner">
                <Image
                  src={bannerSmallScreen}
                  width="100%"
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '1rem',
                    boxShadow: '0 0 1rem black',
                  }}
                />
              </div>
            </Box>
          </ContentPanel> :
          <Container sx={containerStyle} maxWidth={false}>
            <Box onClick={handleOpen} width={"100%"}>
              <div title="Disaster Blaster Banner">
                <Image
                  src={bannerGifSrc}
                  sx={{
                    cursor: 'pointer',
                    width: '90%',
                  }}
                />
              </div>
            </Box>
          </Container>}
      </>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="youtube-video-modal"
        aria-describedby="youtube-video-modal-description"
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
          <iframe
            width="90%"
            height="90%"
            src="https://www.youtube.com/embed/fmMlEvPx3Us?si=NP4Bzr276_WNov1r"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
          <IconButton
            onClick={handleClose}
            sx={{
              color: "white",
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );

}


function GameInEvidenceSection() {
  const theme = useTheme();
  const backgroundImageSrc = "images/homepage_db_background_end.jpg";
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();

  return (
    <ContentPanel backgroundImageSrc={backgroundImageSrc} padding={isSmallScreen ? "0rem" : "5rem"}>
      <Row>
        <Paragraph>
          <ParagraphBody center={true}>
            {Localization.get("disaster_blaster_description")}
          </ParagraphBody>
        </Paragraph>
      </Row>
      <Row sx={{ py: isSmallScreen ? "3rem" : "2rem" }}>
        <JarsickWebsiteButton size="large" color={theme.palette.primary.main} onClick={() => navigate("/disasterblaster")}>
          {Localization.get("learn_more_button")}
        </JarsickWebsiteButton>
      </Row >
      <Row sx={{ paddingBottom: "2rem" }}>
        <iframe
          src="https://store.steampowered.com/widget/3194600/"
          frameborder="0"
          width={isSmallScreen ? "100%" : "646"}
          height={isSmallScreen ? "360" : "190"}
        />
      </Row>
    </ContentPanel >
  );
}

function AboutUsSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const backgroundColor = "#0f0716"
  return (
    <div id="about">
      <ContentPanel color={backgroundColor}>
        <ContentPanelTitle> {Localization.get("about_us_section_title")} </ContentPanelTitle>
        <Row weights={[8, 4]} sx={{ py: "2rem" }}>
          <Paragraph>
            <ParagraphBody>
              {Localization.get("gamedev_studio_description")}
            </ParagraphBody>
          </Paragraph>
          <div title="Jarsick Logo">
            <Image
              src="images/jarsick_logo.png"
              animated={false}
              scale={isSmallScreen ? 1 : 1.2}
              sx={{
                marginTop: isSmallScreen ? "2rem" : "0",
              }} />
          </div>
        </Row>
      </ContentPanel>
    </div>
  );
}


export function HomePage() {
  return (
    <>
      <BannerSection />
      <GameInEvidenceSection />
      <AboutUsSection />
      <NewsSection backgroundColor={"#0f0716"} />
      <ContactInfoSection />
    </>
  );
}
