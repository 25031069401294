import "./App.css";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/header";
import { HomePage } from "./pages/home_page";
import strings_en from "./languages/strings_en.json";
import strings_it from "./languages/strings_it.json";
import strings_es from "./languages/strings_es.json";
import strings_fr from "./languages/strings_fr.json";
import strings_ja from "./languages/strings_ja.json";
import strings_zh from "./languages/strings_zh.json";
import strings_de from "./languages/strings_de.json";
import { Localization } from "./component_library/localization";
import { Footer } from "./component_library/footer";
import { useTheme } from "@emotion/react";
import { DisasterBlasterPage } from "./pages/disaster_blaster_page";
import { setupGoogleAnalytics, withGoogleAnalytics } from "./network/google_analytics";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKywrRzFNRsXoCo9TS52igXV8mU_AawMY",
  authDomain: "jarsick-website-9deb0.firebaseapp.com",
  projectId: "jarsick-website-9deb0",
  storageBucket: "jarsick-website-9deb0.appspot.com",
  messagingSenderId: "318254809721",
  appId: "1:318254809721:web:674a2b6ae699cca6498aa8",
  measurementId: "G-QGCRP3EQ0W"
};

setupGoogleAnalytics("G-QGCRP3EQ0W");

/*function AnalyticsTracker() {
  const location = useLocation();  // Hook di React Router per monitorare la posizione

  useEffect(() => {
    // Traccia la visualizzazione di pagina ogni volta che cambia la posizione
    if (window.gtag) {
      window.gtag('config', 'G-QGCRP3EQ0W', {
        page_path: location.pathname + location.search,  // Traccia la pagina corrente
      });
    }
  }, [location]);  // Esegui l'uso effetto quando cambia la route

  return null;  // Non renderizza nulla, serve solo per tracciare
}*/


function App() {

  Localization.addLanguage("en", strings_en);
  Localization.addLanguage("it", strings_it);
  Localization.addLanguage("es", strings_es);
  Localization.addLanguage("fr", strings_fr);
  Localization.addLanguage("ja", strings_ja);
  Localization.addLanguage("zh", strings_zh);
  Localization.addLanguage("de", strings_de);

  const theme = useTheme();
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={withGoogleAnalytics(HomePage)} />
        <Route path="/disasterblaster" element={withGoogleAnalytics(DisasterBlasterPage)} />
      </Routes>
      <Footer color={theme.palette.background.paper} >©2024 Jarsick Games</Footer>
    </Router>
  );
}


export default App;
